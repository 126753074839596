<template>
<div>
  <div class="d-flex justify-space-between flex-md-row flex-column">
    <v-text-field
      class="ma-5"
      v-model="startDate"
      :label="$vuetify.lang.t('$vuetify.base.startDate')"
      type="date"
      clearable
      :rules="[rules.required]"
    ></v-text-field>
    <v-text-field
      class="ma-5"
      v-model="endDate"
      :label="$vuetify.lang.t('$vuetify.base.endDate')"
      type="date"
      clearable
      :rules="[
        rules.dateRange(startDate, endDate)
      ]"
    ></v-text-field>
  </div>
  <div>
    <v-autocomplete
      style="z-index: 100"
      chips
      clearable
      deletable-chips
      class="ma-5"
      :label="$vuetify.lang.t('$vuetify.summary.filterInventoryEvent')"
      placeholder="Leave empty to include all event types"
      multiple
      v-model="types"
      :items="eventTypes"
      item-text="name"
      item-value="value"
    />
  </div>
  <div v-if="types.includes('inputs') || types.includes('outputs')">
    <v-autocomplete
      v-if="processTypes"
      style="z-index: 100"
      chips
      clearable
      deletable-chips
      class="ma-5"
      label="Manufactoring Process Types"
      placeholder="Leave empty to include all process types"
      multiple
      v-model="processTypeIds"
      :items="processTypes"
      item-text="name"
      item-value="id"
    />
  </div>
  <div v-if="types.includes('sold')">
    <v-autocomplete
      v-if="customers"
      style="z-index: 100"
      chips
      clearable
      deletable-chips
      class="ma-5"
      label="Customers"
      placeholder="Leave empty to include all customers"
      multiple
      v-model="customerIds"
      :items="customers"
      item-text="name"
      item-value="id"
    />
  </div>
  <div v-if="types.includes('purchases')">
    <v-autocomplete
      v-if="suppliers"
      style="z-index: 100"
      chips
      clearable
      deletable-chips
      class="ma-5"
      label="Suppliers"
      placeholder="Leave empty to include all suppliers"
      multiple
      v-model="supplierIds"
      :items="suppliers"
      item-text="name"
      item-value="id"
    />
  </div>
</div>
</template>

<script>
import { rules } from "@/store/util/rules.js";
import { mapGetters } from "vuex";
import moment from 'moment';

export default {
    props: {
        value: {
            type: Object,
        }
    },
    data: () => ({
      rules,
      eventTypes: [
        {name: 'Manufactoring Input', value: 'inputs'},
        {name: 'Manufactoring Output', value: 'outputs'},
        {name: 'Purchases', value: 'purchases'},
        {name: 'Adjustments', value: 'adjustments'},
        {name: 'Sold', value: 'sold'},
      ],
    }),
    computed: {
      ...mapGetters("processes", ["processTypes"]),
      ...mapGetters("company", ["customers", "suppliers"]),
      types: {
        get() {
          return this.value ? this.value.eventTypes:null;
        },
        set (value) {
          const params = Object.assign({}, this.value);
          params.eventTypes = value;
          this.$emit('input', params)
        }
      },
      processTypeIds: {
        get() {
          return this.value ? this.value.processTypes:null;
        },
        set (value) {
          const params = Object.assign({}, this.value);
          params.processTypes = value;
          this.$emit('input', params)
        }
      },
      customerIds: {
        get() {
          return this.value ? this.value.customers:null;
        },
        set (value) {
          const params = Object.assign({}, this.value);
          params.customers = value;
          this.$emit('input', params)
        }
      },
      supplierIds: {
        get() {
          return this.value ? this.value.suppliers:null;
        },
        set (value) {
          const params = Object.assign({}, this.value);
          params.suppliers = value;
          this.$emit('input', params)
        }
      },
      startDate: {
        get () {
          const parsedDate = this.value ? moment(this.value.startDate):null;
          if(parsedDate && parsedDate.isValid()){
            return parsedDate.format('YYYY-MM-DD');
          }
          else{ 
            return '';
          }
        },
        set (value) {
          const params = Object.assign({}, this.value);
          const parsedDate = moment(value);
          const endDateMoment = moment(this.endDate);
          if(parsedDate && parsedDate.isValid()){
            params.startDate = value;

            if(endDateMoment && endDateMoment.isValid() && endDateMoment.isBefore(parsedDate)){
              //set end date to the same day as the start date
              this.endDate = value;
              params.endDate = value;
            }
          }
          else{ 
            params.startDate = null
          }
          this.$emit('input', params)
        }
      },
      endDate: {
        get () {
          const parsedDate = this.value ? moment(this.value.endDate):null;
          if(parsedDate && parsedDate.isValid()){
            return parsedDate.format('YYYY-MM-DD');
          }
          else{ 
            return '';
          }
        },
        set (value) {
          const params = Object.assign({}, this.value);
          const parsedDate = moment(value);
          if(parsedDate && parsedDate.isValid()){
            params.endDate = parsedDate.toDate();
          }
          else{ 
            params.endDate = null
          }
          this.$emit('input', params)
        }
      }
    },
};
</script>

<style></style>
